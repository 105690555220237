/*
 * Formulaire pour la création et la modification des hôtels
 */

import { useAuth } from "react-oidc-context";
import React, { useContext, useEffect, useId, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, FormGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ContenerAction from "utils/ContenerAction";
import InfoForm from "utils/InfoForm";
import Notation from "utils/Notation";
import Translation from "utils/Translation";
import { useNavigate } from "react-router-dom";

import { HotelContext } from "context/HotelContext";

function FormHotel(props) {
    const keycloak = useAuth();
    const { hId, sethId } = useContext(HotelContext);
    const [modifyRight, setModifyRight] = useState(false);
    const [deleteRight, setDeleteRight] = useState(null);

    const [name, setName] = useState(props.hotel.hName);
    const [address, setAddress] = useState(props.hotel.hAddress);
    const [pc, setCp] = useState(props.hotel.hPC);
    const [city, setVille] = useState(props.hotel.hCity);
    const [country, setPays] = useState(props.hotel.hCountry);
    const [stars, setEtoile] = useState(props.hotel.hStars);
    const [nbRooms, setNbChambre] = useState(props.hotel.hNbRooms);
    const [heating, setHeating] = useState(props.hotel.hHeating);

    //const [isNewHotel, setIsNewHotel] = useState(false);
    const [idNewHotel, setIdNewHotel] = useState(null);
    const [reponse, setReponse] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [hasReponse, setHasReponse] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [wantDelete, setWantDelete] = useState(false);

    const [containsNonDigits, setContainsNonDigits] = useState(false);

    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        saveReponse();
    };

    const makeInvisible = () => {
        setLoading(true);

        const link = "/api/hotels/" + props.hotel.hId;

        fetch(link, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + keycloak.user.access_token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: name,
                address: address,
                pc: pc,
                city: city,
                country: country,
                stars: stars,
                nbRooms: nbRooms,
                visible: 0,
                heating: heating,
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                setHasError(false);
                setReponse(res);
                setHasReponse(true);
                setIsDeleted(true);
            })
            .catch((err) => {
                setHasReponse(true);
                setHasError(true);
            });
        setLoading(false);
    };

    const fetchAccess = () => {
        setLoading(true);
        const uId = keycloak.user.profile.sub;
        fetch("/api/hotels/" + hId + "/accessHotels/" + uId, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + keycloak.user.access_token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setModifyRight(data.accessHotel.ahModify);
                setDeleteRight(data.accessHotel.ahDelete);
            })
            .catch((err) => {
                setHasError(true);
            });
        setLoading(false);
    };

    useEffect(() => {
        // console.log(hotel)
        // const
        if (props.mode != "create") {
            fetchAccess();
        }
        //console.log(modifyRight);

        //keycloak.isAuthenticated && hId ? fetchHotel() : setHotel([])
    }, [hId]);

    // const handleCancel

    const saveReponse = () => {
        // console.log(keycloak.token)
        setLoading(true);
        const link =
            props.mode === "create"
                ? "/api/hotels/"
                : "/api/hotels/" + props.hotel.hId;

        fetch(link, {
            method: props.mode === "create" ? "POST" : "PUT",
            headers: {
                Authorization: "Bearer " + keycloak.user.access_token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: name,
                address: address,
                pc: pc,
                city: city,
                country: country,
                stars: stars,
                nbRooms: nbRooms,
                visible: 1,
                heating: heating,
            }),
        })
            .then((res) => {
                // console.log(res)
                return res.json();
            })
            .then((res) => {
                // console.log('res error')
                // console.log(res.error)

                if (res.error !== undefined) {
                    setHasError(true);
                    setHasReponse(true);
                    setReponse(res);
                    setLoading(false);
                } else {
                    // Quand c'est bon en fonction du mode

                    if (props.mode === "create") {
                        // On récupère l'hôtel id pour le mettre dans le context
                        if (res.hotel.hId) {
                            //setIsNewHotel(true);
                            setIdNewHotel(res.hotel.hId);
                            setLoading(false);
                            sethId(res.hotel.hId);
                            navigate("/hotel");

                            //used for the inscription
                            //props.setHotelCreated(res.hotel.hId);
                        }
                    } else {
                        // Pour l'update
                        setHasError(false);
                        setLoading(false);
                        setReponse(res);
                        setHasReponse(true);
                    }
                }
            })
            .catch((err) => {
                // console.log('err')
                // console.log(err)
                setHasReponse(true);
                setHasError(true);
                setLoading(false);
            });
    };

    const handleNumberOfRoomsChange = (e) => {
        const cleanedInput = e.target.value.replace(/\D/g, "");
        setNbChambre(cleanedInput);

        // Verifies if the entry is not a number to put a warning message
        if (e.target.value !== cleanedInput) {
            setContainsNonDigits(true);
        } else {
            setContainsNonDigits(false);
        }
    };

    return isLoading ? (
        <div>
            <Translation tag={"Saving"} />
        </div>
    ) : // : isNewHotel && props.type != "inscription" ? (
    //     <>
    //         Bravo pour la création de votre hôtel{" "}
    //         <Link to="/user/hotels">
    //             <Button>Retour à mes hôtels</Button>
    //         </Link>
    //         <Link to="/hotel">
    //             <Button onClick={() => sethId(idNewHotel)}>
    //                 Accéder à mon hotel
    //             </Button>
    //         </Link>
    //     </>
    // )
    isDeleted ? (
        <div>
            <Translation tag={"Delete"} />{" "}
            <Link to="/user/hotels/">
                <Button onClick={() => sethId(null)}>
                    <Translation tag={"Back_hotel"} />
                </Button>
            </Link>
        </div>
    ) : wantDelete ? (
        <div>
            <Translation tag={"Delete_hotel"} />{" "}
            <Button onClick={() => setWantDelete(false)}>
                <Translation tag={"Cancel"} />
            </Button>{" "}
            <Button onClick={() => makeInvisible()}>
                <Translation tag={"Delete_H"} />
            </Button>
        </div>
    ) : (
        <Form className="form formLwe" onSubmit={handleSubmit}>
            {hasReponse ? (
                // @ts-ignore
                <InfoForm status={hasError ? "NOK" : "OK"} reponse={reponse} />
            ) : (
                <></>
            )}
            <FormGroup className="border_bottom">
                <Row className="oneFormInput">
                    <Col xs="4" className="left">
                        <div className="email-container">
                            <label
                                htmlFor="exampleEmail"
                                className="form-label"
                            >
                                <Translation tag={"Hotel_Name"} />
                            </label>
                            {/* <label
                                htmlFor="exampleEmail"
                                className="form-label text-muted small"
                            >
                                <Translation tag={"Intern_name"} />
                            </label> */}
                        </div>
                    </Col>
                    <Col xs="8" className="form-input">
                        <input
                            name="nom_hotel"
                            value={name}
                            className="lweInput"
                            onChange={(e) => setName(e.target.value)}
                            id="nom_hotel"
                            placeholder="Hôtel du port"
                            autoComplete="none"
                            disabled={true}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup className="border_bottom">
                <Row className="oneFormInput">
                    <Col xs="4" className="left">
                        <div className="email-container">
                            <label
                                htmlFor="exampleEmail"
                                className="form-label"
                            >
                                <Translation tag={"Intern_name"} />
                            </label>
                        </div>
                    </Col>
                    <Col xs="8" className="left">
                        <label
                            htmlFor="exampleEmail"
                            className="form-label text-muted small"
                        >
                            <Translation tag={"Address"} />
                        </label>
                        <div className="form-input">
                            <input
                                name="adresse"
                                value={address}
                                className="lweInput"
                                onChange={(e) => setAddress(e.target.value)}
                                id="adresse"
                                placeholder="15 rue du port"
                                disabled={true}
                            />
                        </div>
                        <label
                            htmlFor="exampleEmail"
                            className="form-label text-muted small"
                        >
                            <Translation tag={"City"} />
                        </label>
                        <div className="form-input">
                            <input
                                name="ville"
                                value={city}
                                className="lweInput"
                                onChange={(e) => setVille(e.target.value)}
                                id="ville"
                                placeholder="Paris"
                                disabled={true}
                            />
                        </div>
                        <label
                            htmlFor="exampleEmail"
                            className="form-label text-muted small"
                        >
                            <Translation tag={"PC"} />
                        </label>
                        <div className="form-input">
                            <input
                                name="cp"
                                value={pc}
                                className="lweInput"
                                onChange={(e) => setCp(e.target.value)}
                                id="cp"
                                placeholder="75000"
                                disabled={true}
                            />
                        </div>
                        <label
                            htmlFor="exampleEmail"
                            className="form-label text-muted small"
                        >
                            <Translation tag={"Country"} />
                        </label>
                        <div className="form-input">
                            <input
                                name="pays"
                                value={country}
                                className="lweInput"
                                onChange={(e) => setPays(e.target.value)}
                                id="pays"
                                placeholder="France"
                                disabled={true}
                            />
                        </div>
                    </Col>
                </Row>
            </FormGroup>

            <FormGroup className="border_bottom">
                <Row className="oneFormInput">
                    <Col xs="4" className="left">
                        <div className="email-container">
                            <label
                                htmlFor="exampleEmail"
                                className="form-label"
                            >
                                <Translation tag={"DetailsHotelTitle"} />
                            </label>
                        </div>
                    </Col>
                    <Col xs="8" className="left">
                        <label
                            htmlFor="exampleEmail"
                            className="form-label text-muted small"
                        >
                            <Translation tag={"heatingType"} />
                        </label>
                        <div className="form-input">
                            <input
                                name="typeChauffage"
                                value={heating}
                                className="lweInput"
                                onChange={(e) => setHeating(e.target.value)}
                                id="heating"
                                placeholder="Électrique"
                                disabled={true}
                            />
                        </div>
                        <label
                            htmlFor="exampleEmail"
                            className="form-label text-muted small"
                        >
                            <Translation tag={"priceWater"} />
                        </label>
                        <div className="form-input">
                            <input
                                name="priceWater"
                                value="4 €"
                                className="lweInput"
                                // onChange={(e) => setHeating(e.target.value)}
                                id="priceW"
                                placeholder="4 €"
                                disabled={true}
                            />
                        </div>
                        <label
                            htmlFor="exampleEmail"
                            className="form-label text-muted small"
                        >
                            <Translation tag={"priceEnergy"} />
                        </label>
                        <div className="form-input">
                            <input
                                name="priceEnergy"
                                value="0.2 €"
                                className="lweInput"
                                // onChange={(e) => setHeating(e.target.value)}
                                id="priceE"
                                placeholder="0.2 €"
                                disabled={true}
                            />
                        </div>
                    </Col>
                </Row>
            </FormGroup>
            <ContenerAction className="Bouton_form">
                {modifyRight ? (
                    <Button
                        className="bouton_annuler"
                        onClick={() => props.setReload(true)}
                    >
                        <Translation tag={"Cancel"} />
                    </Button>
                ) : props.mode == "create" && props.type != "inscription" ? (
                    <Link to="/user/hotels">
                        <Translation tag={"Back"} />
                    </Link>
                ) : (
                    <></>
                )}
            </ContenerAction>
        </Form>
    );
}

export default FormHotel;
