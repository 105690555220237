import React from "react";
import { Navigate } from "react-router-dom";
import Base from "./views/Base";
import CentralizeRedirect from "auth/CentralizeRedirect";
import ScrollToTop from "utils/ScrollToTop";

const wrapWithScrollToTop = (component) => (
    <ScrollToTop>
        {component}
    </ScrollToTop>
)

const routes = [
    {
        path: "/hotel/",
        // element: <Base pageName="Home hôtel" viewID={"hotel"} />,
        element: <Navigate to="/hotel/lunishower/dashboard" replace />,
    },
    {
        path: "/settings/hotel/informations",
        element: wrapWithScrollToTop(
            <Base pageName="Mes réglages" viewID={"hotelInformations"} />
        ),
    },
    {
        path: "/settings/hotel/rooms",
        element: wrapWithScrollToTop(
            <Base pageName="Gestion des chambres" viewID={"rooms_settings"} />
        ),
    },
    {
        path: "/hotel/lunishower",
        element: <Navigate to="/hotel/lunishower/dashboard" replace />,
    },
    {
        path: "/hotel/lunishower/dashboard",
        element: wrapWithScrollToTop(
            <Base pageName="Tableau de bord" viewID={"dashboard"} />
        ),
    },
    {
        path: "/hotel/lunishower/consommation",
        element: wrapWithScrollToTop(
            <Base pageName="Consommation" viewID={"consommation"} />
        ),
    },
    {
        path: "/hotel/lunishower/economies",
        element: wrapWithScrollToTop(
            <Base pageName="Economies" viewID={"economies"} />
        )
    },
    {
        path: "/hotel/lunishower/rooms",
        element: wrapWithScrollToTop(
            <Base pageName="Les chambres" viewID={"rooms"} />
        ),
    },
    {
        path: "/hotel/lunishower/rooms/:roomId",
        element: wrapWithScrollToTop(
            <Base pageName="Ma chambre" viewID={"room"} />
        ),
    },
    {
        path: "/hotel/lunishower/donation",
        element: wrapWithScrollToTop(
            <Base pageName="Mes dons" viewID={"donation"} />
        ),
    },
    {
        path: "/hotel/lunishower/customer_xp",
        element: wrapWithScrollToTop(
            <Base pageName="Mon experience client" viewID={"customer_xp"} />
        ),
    },
    {
        path: "/hotel/lunishower/abonnement",
        element: wrapWithScrollToTop(
            <Base pageName="Mon abonnement" viewID={"abonnement"} />
        ),
    },
    {
        path: "/settings/hotel/access",
        element: wrapWithScrollToTop(
            <Base pageName="Gestion des accès" viewID={"myAccess"} />
        ),
    },
    {
        path: "/settings/hotel/devices",
        element: wrapWithScrollToTop(
            <Base pageName="Gestion des appareils" viewID={"devices"} />
        ),
    },
    {
        path: "/user/group/ajouter",
        element: wrapWithScrollToTop(
            <Base pageName="Ajouter un groupe" viewID={"addGroup"} />
        ),
    },
    {
        path: "/user/hotels/ajouter",
        element: wrapWithScrollToTop(
            <Base pageName="Ajouter un hôtel" viewID={"addHotel"} />
        ),
    },
    {
        path: "/user/informations",
        element: wrapWithScrollToTop(
            <Base pageName="Mes informations" viewID={"personalInformations"} />
        ),
    },
    {
        path: "/user/hotels",
        element: wrapWithScrollToTop(
            <Base pageName="Mes hôtels" viewID={"myHotels"} />
        ),
    },
    {
        path: "/user/statistics",
        element: wrapWithScrollToTop(
            <Base pageName="Mes statistiques" viewID={"statistics"} />
        ),
    },
    {
        path: "/user/preferences",
        element: wrapWithScrollToTop(
            <Base pageName="Mes préférences" viewID={"preferences"} />
        ),
    },
    {
        path: "/user/accessAPI",
        element: wrapWithScrollToTop(
            <Base pageName="Accès API" viewID={"accessAPI"} />
        ),
    },
    {
        path: "/inscription",
        element: wrapWithScrollToTop(
            <Base pageName="Inscription" viewID={"inscription"} />
        ),
    },
    {
        path: "/redirect",
        element: <CentralizeRedirect />,
    },
    {
        path: "/",
        element: wrapWithScrollToTop(
            <Base pageName="Mon hôtel" viewID={"main"} />
        ),
    }
    // { path: "*", element: <Navigate to="/" replace /> },
    // Add more routes as needed
    // ...
];

export default routes;
