import React from 'react';
import { Link } from 'react-router-dom';
import Translation from 'utils/Translation';

function Avis(props) {
    return (
        <div className="Avis">
            <a href={props.link} id="review-card" class="review-card" target="_blank">
                <div class="review-header">
                    <span id="reviewer-name">{props.Name}</span>
                    <span id="review-rating">{props.note}</span>
                </div>
                <div class="review-date" id="review-date">
                    Le {props.date} via {props.site}
                </div>
                <div class="review-content" id="review-content">
                    {props.Description}
                </div>
            </a>   
        </div>
    )
}

export default Avis;
