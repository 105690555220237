import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import ContenerSaaS from "../utils/ContenerSaaS";
import HotelApercu from "../component/HotelApercu";
import ContenerAction from "utils/ContenerAction";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Groups from "component/Groups";
import GroupInfos from "./GroupInfos";
import AddGroup from "./AddGroup";
import Translation from "utils/Translation";

const ChoiceHotel = () => {
    const keycloak = useAuth();
    const [hotels, setHotels] = useState([]);
    const [invitations, setInvitations] = useState([]);
    const [groups, setGroups] = useState([]);
    const [indep, setIndep] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const [submit, setSubmit] = useState(false);

    const [groupEditable, setGroupEditable] = useState(null);

    const fetchInvitations = () => {
        const uid = keycloak.user.profile.sub;
        fetch("/api/hotels/invitations", {
            method: "get",
            headers: {
                Authorization: "Bearer " + keycloak.user.access_token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                // console.log(response)
                return response.json();
            })
            .then((data) => {
                // Ca marche
                // console.log(data);
                if (data.success == "OK") {
                    setInvitations(data.hotels);
                    setLoading(false);
                } else {
                    setHasError(true);
                    setLoading(false);
                }

                // setHotels(data)
            })
            .catch((err) => {
                // Err
                console.log(err);
                setHasError(true);
                setLoading(false);
            });
    };

    useEffect(() => {
        const fetchHotel = () => {
            setLoading(true);
            // console.log(keycloak.user.access_token);
            const uid = keycloak.user.profile.sub;
            // Requête pour afficher tous les hôtels
            fetch("/api/groups/hotels", {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + keycloak.user.access_token,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    // console.log(response)
                    return response.json();
                })
                .then((data) => {
                    // Ca marche
                    // console.log(data);
                    if (data.success == "OK") {
                        setGroups(data.groups);
                        setIndep(data.independent);
                        fetchInvitations();
                    } else {
                        setHasError(true);
                        setLoading(false);
                    }

                    // setHotels(data)
                })
                .catch((err) => {
                    // Err
                    console.log(err);
                    setHasError(true);
                    setLoading(false);
                });
        };

        // const

        keycloak.isAuthenticated ? fetchHotel() : setHotels([]);
    }, [groupEditable, keycloak.isAuthenticated]);

    const elemBtn = keycloak.isAuthenticated ? (
        groupEditable != null ? (
            <ContenerAction>
                <Link to={"/user/hotels"}>
                    <Button onClick={() => setGroupEditable(null)}>
                        Annuler
                    </Button>
                </Link>

                <Button onClick={() => setSubmit(true)}>Enregistrer</Button>
            </ContenerAction>
        ) : (
            <>
                <Link to={"/user/hotels/ajouter"}>
                    <Button>Ajouter un hôtel</Button>
                </Link>
                <Link to={"/user/group/ajouter"}>
                    <Button>Créer un groupe</Button>
                </Link>
            </>
        )
    ) : (
        <></>
    );

    return (
        <ContenerSaaS
            title={<Translation tag="Mes_hôtels" />}
            subtitle="liste_hotel"
            notMaxHeight={false}
        >
            <div className="mainContener">
                {!keycloak.isAuthenticated ? (
                    <div className="cntContener">
                        {/* Pour accéder à vos hôtels, il faut se connecter */}
                        <Translation tag="Connection_required_Hotel" />
                    </div>
                ) : isLoading ? (
                    // <div className="cntContener">Chargement...</div>
                    <div className="cntContener">
                        <Translation tag="Loader" />
                    </div>
                ) : hasError ? (
                    <div className="cntContener">
                        Une erreur est survenue lors du chargement des hôtels.
                    </div>
                ) : (
                    <>
                        {indep.length > 0 ? (
                            <>
                                {indep.map((hotel) => (
                                    <HotelApercu
                                        key={"hotel" + hotel.hId}
                                        data={hotel}
                                        type="aperçu"
                                    />
                                ))}
                            </>
                        ) : (
                            <div>Aucun hôtel disponible pour le moment.</div>
                        )}
                    </>
                )}
            </div>
        </ContenerSaaS>

        // <ContenerSaaS
        //     title={ groupEditable != null ? "Modifier mon groupe" : "Mes_hotels" }
        //     subtitle={"liste_hotel"}
        //     notMaxHeight={false}
        // >
        //     <div className="mainContener">
        //         {!keycloak.isAuthenticated ? (
        //             <div className="cntContener">
        //                 Pour accéder à vos hôtels, il faut se connecter
        //             </div>
        //         ) : isLoading ? (
        //             <div className="cntContener">Chargement...</div>
        //         ) : hasError ? (
        //             <div className="cntContener">Une erreur est survenue lors du chargement des hôtels.</div>
        //         ) : (

        // ) : groupEditable == null ? (
        //     <>
        //         {invitations.length != 0 ? (
        //             <>
        //                 <div>Invitations à un hôtel</div>
        //                 {invitations.map((invitation) => (
        //                     <HotelApercu
        //                         key={"hotel" + invitation.hId}
        //                         data={invitation}
        //                         type={"invitation"}
        //                     />
        //                 ))}
        //             </>
        //         ) : (
        //             <div>Aucune invitation en attente.</div>
        //         )}

        //         {groups.length > 0 ? (
        //             <>
        //                 {groups.map((group) => (
        //                     <Groups
        //                         key={"group" + group.gId}
        //                         group={group}
        //                         setGroupEditable={setGroupEditable}
        //                     />
        //                 ))}
        //             </>
        //         ) : (
        //             <div>Aucun groupe d'hôtels disponible pour le moment.</div>
        //         )}

        //         {indep.length > 0 ? (
        //             <>
        //                 {indep.map((hotel) => (
        //                     <HotelApercu
        //                         key={"hotel" + hotel.hId}
        //                         data={hotel}
        //                         type={"aperçu"}
        //                     />
        //                 ))}
        //             </>
        //         ) : (
        //             <div>Aucuns hôtels indépendants disponibles pour le moment.</div>
        //         )}
        //     </>
        // ) : (
        //     <AddGroup
        //         groupEditable={groupEditable}
        //         type={"edit"}
        //         setGroupEditable={setGroupEditable}
        //         submit={submit}
        //         setSubmit={setSubmit}
        //     ></AddGroup>
        // )}
        //     </div>
        // </ContenerSaaS>
    );
};

export default ChoiceHotel;
