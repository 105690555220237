// //Avec Route Browser
import React, { useEffect, useState } from "react";
// import { Navigate, Outlet, Route, Routes } from "react-router-dom";

// import logo from "./logo.svg";
import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";

// import Base from "./views/Base.js";
// import Main from "./views/Home.js";
// import PrivateRoute from "./auth/PrivateRoute";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./routes";
import { HotelContextProvider } from "./context/HotelContext";
//import CentralizeRedirect from "auth/CentralizeRedirect";
// import replicated from "auth/replicated";
// import Home from "./views/Home.js";
import { useAuth } from "react-oidc-context";
// import ScrollToTop from "utils/ScrollToTop";

const router = createBrowserRouter(routes);

const App = () => {
    const keycloak = useAuth();

    const [loading, setLoading] = useState(true);

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Fonction de vérification de la largeur de la fenêtre
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        // Vérifiez la largeur au chargement
        handleResize();

        // Écoutez les changements de taille d'écran
        window.addEventListener("resize", handleResize);

        // Nettoyez l'événement lors du démontage
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (keycloak.isAuthenticated) {
            console.log("L'utilisateur est authentifié");
            setLoading(false);
        } else {
            setLoading(false);
        }
    }, [keycloak.isAuthenticated]);

    const login = () => {
        keycloak.signinRedirect();
    };

    const logout = () => {
        keycloak.signoutRedirect();
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (isMobile) {
        return (
            <div style={{ textAlign: "center", padding: "20px" }}>
                <h1>Application non disponible sur mobile</h1>
                <p>
                    Veuillez utiliser cette application sur un appareil de plus
                    grande taille.
                </p>
            </div>
        );
    }

    return (
        <HotelContextProvider>
            {/* Utilisation du RouterProvider pour gérer les routes */}
            <RouterProvider router={router} />
            {/* Boutons de connexion et déconnexion */}
            {/* {!keycloak.isAuthenticated ? (
                    <button onClick={login}>Se connecter</button>
                ) : (
                    <button onClick={logout}>Se déconnecter</button>
                )} */}
        </HotelContextProvider>
    );
};

// Example of a private component accessible only for authenticated users
const PrivateComponent = () => {
    return <h2>Private Component - Only accessible for authenticated users</h2>;
};

export default App;
