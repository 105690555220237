import React, { useState, useEffect } from 'react';
import Translation from "utils/Translation";
import droplet from "../img/droplet.svg"
import cloud from "../img/Cloud.svg"
import money from "../img/money.svg"
import StatCard from "component/Stat_card";
import DonationCard from 'component/Donation_card';
import bdd_don from "../BDD/bdd_don.json";
import BreadCrumb from 'component/Breadcrumb';

const Donation = () => {
    const [stats, setStats] = useState({
      numberOfShowers: 0,
      totalWaterSaved: 0,
    });

    const formatDate = (dateString) => {
      const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
      return new Date(dateString).toLocaleDateString('fr-FR', options);
    };
    const sortedDonations = bdd_don.sort((a, b) => new Date(b.dDeliveryDate) - new Date(a.dDeliveryDate));
    const totalDonations = sortedDonations.length;
    const totalWaterGiven = sortedDonations.reduce((acc, donation) => acc + donation.dWaterGive, 0);


  return <>
  <div className='Donation_global'>
    <BreadCrumb text="donation_title" />
    <h1 className="h1-page">
      <Translation tag={"donation_title"} />
    </h1>
    <div className="col_dashboard_number">
    <StatCard number={totalWaterGiven.toLocaleString('fr-FR')} text1="L"  text1Color="#deecf7" text2="Eau_reverse" icon={droplet}> </StatCard>
    <StatCard number={Math.ceil(totalWaterGiven/6000).toLocaleString('fr-FR')} text1Color="#deecf7" text2="Water_accessed" icon={cloud}> </StatCard>
    <StatCard number={totalDonations} text1=""  text1Color="#FFFF" text2="Certificats" icon={money}> </StatCard>
    </div>
    <div className="Partie2-donation">
      <h2 className="h2-page">
          <Translation tag={"Donation_certificate"} />
      </h2>
      {sortedDonations.length > 0 ? (
        sortedDonations.map((don) => (
          <DonationCard
            key={don.dNumber}
            number={don.dNumber.toString()}
            date={formatDate(don.dDeliveryDate)}
            periode={`${formatDate(don.dStartDate)} - ${formatDate(don.dEndDate)}`}
            liters={don.dWaterGive.toLocaleString('fr-FR')}
            link={don.dlink}
          />
        ))
      ) : (
        <p><Translation tag={"No_donation"}/></p>
    )}
    </div>
      <h2 className="h2-page">
            <Translation tag={"Learn_More_Water_Donation"} />
      </h2>
    <div className="MadeBlueFoundation">
      <h3 className="Foundation-title"><Translation tag={"Made_Blue_Found"} /> </h3>
      <div className="Description_Madeblue">
      <p className="MadeBlueText"><Translation tag={"Made_Blue_description"}></Translation></p>
      <a href="https://madeblue.org/en/"><button className="MadeBlueMore"><Translation tag={"See_more"}></Translation></button> </a>
      </div>
    </div>
  </div>
  </>;
};

export default Donation;
