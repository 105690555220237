//import React from "react";
import React, { useContext, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { Button, Col, FormGroup, Row } from "react-bootstrap";
import { Form, Link, Navigate } from "react-router-dom";
import ContenerAction from "utils/ContenerAction";
import InfoForm from "utils/InfoForm";
import HotelApercu from "component/HotelApercu";
import AjouterHotel from "./AjouterHotel";

import { HotelContext } from "context/HotelContext";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook

const Inscription = () => {
    //The different steps to finalise the inscription
    //Step 1: complete the date of birth and job
    //Step 2 (optional) : accepte the pending invitations if any and create a hotel if wanted
    //Step 3: ...
    const keycloak = useAuth();
    const { hId, sethId } = useContext(HotelContext);
    const [step, setStep] = useState(1);
    const [user, setUser] = useState({});
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [mail, setMail] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [job, setJob] = useState("");
    const [invitations, setInvitations] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [hasReponse, setHasReponse] = useState(false);
    const [reponse, setReponse] = useState({});
    const [createHotel, setCreateHotel] = useState(false);
    //const [hotelCreated, setHotelCreated] = useState(null);
    const navigate = useNavigate(); // Initialize the useHistory hook

    // console.log("Inscription - keycloak : ", keycloak);

    useEffect(() => {
        const fetchUser = () => {
            setLoading(true);
            fetch("/api/users/me", {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + keycloak.user.access_token,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    // console.log("Inscription - Data : ", data);
                    setLoading(false);
                    setUser(data.user);
                    setBirthDate(data.user.uBirthDate || "");
                    setJob(data.user.uJob || "");
                    setName(data.user.uName || "");
                    setSurname(data.user.uSurname || "");
                    setMail(data.user.uMail || "");

                    // console.log("Inscription - Data : ", data);
                })
                .catch((err) => {
                    setHasError(true);
                    setHasReponse(true);
                    setLoading(false);
                });
        };

        fetchUser();
    }, [keycloak.isAuthenticated]);

    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log("coucou");

        if (birthDate && job) {
            saveReponse();
        } else {
            setReponse({
                message: "Un des champs est vide",
            });
            setHasError(true);
            setHasReponse(true);
        }
    };

    const saveReponse = () => {
        setLoading(true);
        fetch("/api/users/", {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + keycloak.user.access_token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: name,
                surname: surname,
                mail: mail,
                birthDate: birthDate,
                job: job,
                visible: 1,
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                // console.log('res error')
                // console.log(res.error)
                // console.log("Inscription - Response : ", res);
                if (res.error !== undefined) {
                    setHasError(true);
                    setReponse(res);
                } else {
                    setHasError(false);
                    setReponse(res);
                    fetchInvitations();
                    setStep(step + 1);
                }
                setLoading(false);
                setHasReponse(true);
            })
            .catch((err) => {
                // console.log('err')
                // console.log(err)
                setHasReponse(true);
                setHasError(true);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (birthDate) {
            const initialDate = new Date(birthDate);
            const formattedDate = initialDate.toLocaleDateString("fr-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
            setBirthDate(formattedDate);
        }
    }, [birthDate]);

    const fetchInvitations = () => {
        // console.log("Inscription - fetchInvitations");
        const uid = keycloak.user.profile.sub;
        fetch("/api/hotels/invitations", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + keycloak.user.access_token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success == "OK") {
                    setInvitations(data.hotels);
                } else {
                    setHasError(true);
                }
                setLoading(false);
            })
            .catch((err) => {
                setHasError(true);
                setLoading(false);
            });
    };

    return (
        <div>
            <h1>Finalisez votre inscription</h1>
            {step == 1 ? (
                <>Complétez votre profil</>
            ) : step == 2 ? (
                <>Ajoutez des hôtels</>
            ) : null}
            <br />
            {step == 1 ? (
                    <Form className="form formLwe" onSubmit={handleSubmit}>
                        {hasReponse ? (
                            <InfoForm
                                status={hasError ? "NOK" : "OK"}
                                reponse={reponse}
                            />
                        ) : (
                            <></>
                        )}
                        <FormGroup>
                            <Row className="oneFormInput">
                                <Col xs="4" className="left">
                                    <label htmlFor="birthDate">
                                        Birth Date 2
                                    </label>
                                </Col>
                                <Col xs="8" className="left">
                                    <input
                                        type="date"
                                        name="birthDate"
                                        value={birthDate}
                                        className="lweInput"
                                        onChange={(e) =>
                                            setBirthDate(e.target.value)
                                        }
                                        id="birthDate"
                                        placeholder="2002-09-23"
                                    />
                                </Col>
                            </Row>
                            <Row className="oneFormInput">
                                <Col xs="4" className="left">
                                    <label htmlFor="job">Job</label>
                                </Col>
                                <Col xs="8" className="left">
                                    <input
                                        name="job"
                                        value={job}
                                        className="lweInput"
                                        onChange={(e) => setJob(e.target.value)}
                                        id="job"
                                        placeholder="Sustainable Development Manager"
                                    />
                                </Col>
                            </Row>
                            <ContenerAction>
                                <input
                                    type="submit"
                                    className="btnLwe btn btn-primary btnSave"
                                    value="Suivant"
                                />
                            </ContenerAction>
                        </FormGroup>
                    </Form>
            ) : step == 2 ? (
                <>
                    {invitations.length !== 0 ? (
                        <>
                            <div>Invitations a un hotel</div>
                            {invitations.map((invitation) => (
                                <HotelApercu
                                    key={"hotel" + invitation.hId}
                                    data={invitation}
                                    type={"invitation"}
                                />
                            ))}
                        </>
                    ) : (
                        <></>
                    )}
                    {!createHotel ? (
                        <Button onClick={() => setCreateHotel(true)}>
                            Créer un hôtel
                        </Button>
                    ) : (
                        <AjouterHotel
                            type={"inscription"}
                        />
                    )}
                    <Link to={"/user/hotels"}>
                        <Button>Skip et terminer</Button>
                    </Link>
                </>
            ) : null}
        </div>
    );
};

export default Inscription;