import React, { useState, useEffect, useContext } from "react";
import { HotelContext } from "context/HotelContext";
import BreadCrumb from "component/Breadcrumb";
import Translation from "utils/Translation";
import StatCard_simple from "component/Stat_card";
import { useAuth } from "react-oidc-context";
import ConsBarChartComponent from "component/ConsBarChartComponent";

const Consommation = () => {
    const keycloak = useAuth();
    const { hId } = useContext(HotelContext);

    const [chartDataCons, setChartDataCons] = useState([]);
    const [chartDataTemp, setChartDataTemp] = useState([]);
    const [stats, setStats] = useState({
        averageTemoinConsumption: 0,
        averageEssentielConsumption : 0,
        averageExperienceConsumption : 0,
        averageTemoinTemp: 0,
        averageEssentielTemp: 0,
        averageExperienceTemp: 0
    });
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [selectedPeriodType, setSelectedPeriodType] = useState("year_start");
    const [activeButton, setActiveButton] = useState("year_start");

    const formatConsWaterChartData = (data) =>
        data.map((item) => ({
            month: new Date(`${item.month || "N/A"}-01`).toLocaleDateString("fr-FR", {
                month: "long",
                year: "numeric",
            }),
            graphAverageTemoinConsumption: Math.round(Number(item.graphAverageTemoinConsumption)) || 0,
            graphAverageEssentielConsumption: Math.round(Number(item.graphAverageEssentielConsumption)) || 0,
            graphAverageExperienceConsumption: Math.round(Number(item.graphAverageExperienceConsumption)) || 0,
        }));

    const formatConsTempChartData = (data) =>
        data.map((item) => ({
            month: new Date(`${item.month || "N/A"}-01`).toLocaleDateString("fr-FR", {
                month: "long",
                year: "numeric",
            }),
            graphAverageTemoinTemp: Math.round(Number(item.graphAverageTemoinTemp)) || 0,
            graphAverageEssentielTemp: Math.round(Number(item.graphAverageEssentielTemp)) || 0,
            graphAverageExperienceTemp: Math.round(Number(item.graphAverageExperienceTemp)) || 0,
        }));

    const fetchStatsByHotel = () => {
        setLoading(true);
        fetch(`/api/hotels/${hId}/stats?periodType=${selectedPeriodType}`, {
            method: "get",
            headers: {
                Authorization: "Bearer " + keycloak.user.access_token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === "OK") {
                    setStats(data.stats);
                    setChartDataCons(formatConsWaterChartData(data.chartDataCons));
                    setChartDataTemp(formatConsTempChartData(data.chartDataTemp));
                    setHasError(false);
                } else {
                    setHasError(true);
                    console.error("Error fetching stats:", data.message);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setHasError(true);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (keycloak.isAuthenticated && keycloak.user && hId) {
            fetchStatsByHotel();
        }
    }, [keycloak.isAuthenticated, hId, selectedPeriodType]);

    const handlePeriodChange = (periodType) => {
        setSelectedPeriodType(periodType);
        setActiveButton(periodType);
    };

    return (
        <div className="consommation">
            <BreadCrumb text="Conso_title" />
            <h1 className="h1-page">
                <Translation tag="Conso_title" />
            </h1>

            <div className="button-container">
                    <button
                        className={`more-button ${activeButton === "beginning" ? "active" : ""}`}
                        onClick={() => handlePeriodChange("beginning")}
                    >
                        <Translation tag={"since_beginning"} />
                    </button>
                    <button
                        className={`more-button ${activeButton === "year_start" ? "active" : ""}`}
                        onClick={() => handlePeriodChange("year_start")}
                    >
                        <Translation tag={"since_year"} />
                    </button>
                    <button
                        className="more-button"
                        onClick={() => handlePeriodChange("quarter_start")}
                    >
                        <Translation tag={"since_quarter"} />
                    </button>
                </div>
            <div className="Dashboard-part-2-bis">
                <div className="Chart_dashboard">
                        <h1 className="Title_graph">
                            <Translation tag="Eau" />
                        </h1>
                        {/* <div className="Dashboard-part-2-bis"> */}

                        {loading ? (
                            <div className="chart-loader">
                                <p className="chart-loader-text">Chargement des données...</p>
                            </div>
                        ) : hasError ? (
                            <div className="chart-loader">
                                <p className="chart-loader-text">Erreur lors du chargement des données. Veuillez réessayer.</p>
                            </div>
                        ) : chartDataCons.length > 0 ? (
                            <ConsBarChartComponent
                                data={chartDataCons}
                                temoinKey="graphAverageTemoinConsumption"
                                essentielKey="graphAverageEssentielConsumption"
                                experienceKey="graphAverageExperienceConsumption"
                                isTemperature={false}
                            />
                        ) : (
                            <div className="chart-loader">
                                <p className="chart-loader-text">Pas de données disponibles pour cette période.</p>
                            </div>
                        )}

                        {/* </div> */}
                </div>
            </div>
                    <div className="col_dashboard_number">
                        <StatCard_simple
                            number={(stats.averageTemoinConsumption || 0).toLocaleString("fr-FR")}
                            text1="L"
                            text1Color="#deecf7"
                            text2="moyenne_temoin"
                        />
                        <StatCard_simple
                            number={(stats.averageEssentielConsumption || 0).toLocaleString("fr-FR")}
                            text1="L"
                            text1Color="#deecf7"
                            text2="moyenne_essentiel"
                        />
                        <StatCard_simple
                            number={(stats.averageExperienceConsumption || 0).toLocaleString("fr-FR")}
                            text1="L"
                            text1Color="#deecf7"
                            text2="moyenne_experience"
                        />
                    </div>

                    <div className="Dashboard-part-2-bis">
                        <div className="Chart_dashboard">
                            <h1 className="Title_graph">
                                <Translation tag="Temperature" />
                            </h1>
                            {/* <div className="Dashboard-part-2-bis"> */}

                            {loading ? (
                                <div className="chart-loader">
                                    <p className="chart-loader-text">Chargement des données...</p>
                                </div>
                            ) : hasError ? (
                                <div className="chart-loader">
                                    <p className="chart-loader-text">Erreur lors du chargement des données. Veuillez réessayer.</p>
                                </div>
                            ) : chartDataTemp.length > 0 ? (
                                <ConsBarChartComponent
                                    data={chartDataTemp}
                                    temoinKey="graphAverageTemoinTemp"
                                    essentielKey="graphAverageEssentielTemp"
                                    experienceKey="graphAverageExperienceTemp"
                                    isTemperature={true}
                                />
                            ) : (
                                <div className="chart-loader">
                                    <p className="chart-loader-text">Pas de données disponibles pour cette période.</p>
                                </div>
                            )}

                            {/* </div> */}
                        </div>
                    </div>

                    <div className="col_dashboard_number">
                        <StatCard_simple
                            number={(stats.averageTemoinTemp || 0).toLocaleString("fr-FR")}
                            text1="°C"
                            text1Color="#f0f7de"
                            text2="moyenne_temoin"
                        />
                        <StatCard_simple
                            number={(stats.averageEssentielTemp || 0).toLocaleString("fr-FR")}
                            text1="°C"
                            text1Color="#f0f7de"
                            text2="moyenne_essentiel"
                        />
                        <StatCard_simple
                            number={(stats.averageExperienceTemp || 0).toLocaleString("fr-FR")}
                            text1="°C"
                            text1Color="#f0f7de"
                            text2="moyenne_experience"
                        />
                    </div>
        </div>
    );
};

export default Consommation;
