// SavingsBarChartComponent.js
import React, { useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import Translation from 'utils/Translation';

const SavingsBarChartComponent = ({ data, energyKey, waterKey, xAxisKey }) => {
  useEffect(() => {
  }, [data]);

  if (!data || data.length === 0) {
      return <p>Pas de données disponibles pour le graphique.</p>;
  }

  const tooltipFormatter = (value, name) => {

    if(name.props.tag === 'Eau'){
      return [`Eau : ${value.toLocaleString("fr-FR")} L`, name];
    } else if(name.props.tag === 'Water'){
        return [`Water : ${value.toLocaleString("fr-FR")} L`, name];
    } else if(name.props.tag === 'Energie'){
        return [`Energie : ${value.toLocaleString("fr-FR")} kWh`, name];
    } else if(name.props.tag === 'Energy'){
        return [`Energy : ${value.toLocaleString("fr-FR")} kWh`, name];
    }
  }

  return (
    <ResponsiveContainer width="100%" height={300}>

      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey={xAxisKey || "month"} />

        {/* Axe des ordonnees qui represente l'energie */}
        <YAxis
          yAxisId="right"
          orientation='right'
          label ={{
            angle: -90,
            position: "insideRight",
          }}
        />

        {/* Axe des ordonnees qui represente l'eau */}
        <YAxis
          yAxisId="left"
          label ={{
            angle: -90,
            position: "insideLeft",
          }}
        />
        <Tooltip formatter={tooltipFormatter} />
        <Legend />
        <Bar
          dataKey={energyKey || "graphTotalEnergySaved"}
          stackId="a"
          fill="#346840"
          name={<Translation tag={"Energie"} />}
          isAnimationActive={false}
          yAxisId="left"
          barSize={30}
        />
        <Bar
          dataKey={waterKey || "graphTotalWaterSaved"}
          stackId="b"
          fill="#106198"
          name={<Translation tag={"Eau"} />}
          isAnimationActive={false}
          yAxisId="right"
          barSize={30}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SavingsBarChartComponent;
