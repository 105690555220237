import React, { useState, useEffect } from "react";
import Translation from "utils/Translation";

function StatCard(props) {
    const [displayNumber, setDisplayNumber] = useState("...");

    // console.log(props);
    // Revalider lorsque props.number change
    useEffect(() => {
        if (props.number === null || props.number === undefined) {
            setDisplayNumber("...");
        } else {
            setDisplayNumber(String(props.number));
        }
    }, [props.number]); // Dépendance sur props.number

    return (
        <div className="stat-card" style={{ color: props.text1Color }}>
            {/* Affiche l'icône si elle est définie */}
            {/* <div className="logo-image-2">
        {props.icon && <img src={props.icon} alt="Icon" className="Logo-svg-2" />}
      </div> */}
            <div style={{ color: props.text1Color }}>
                <span className="stat-number">{displayNumber}</span>{" "}
                <span className="stat-text1">
                    <Translation tag={props.text1} />
                </span>
            </div>
            <div className="stat-text2">
                <Translation tag={props.text2} />
            </div>
        </div>
    );
}

export default StatCard;
