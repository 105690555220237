import React, { useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import Translation from 'utils/Translation';

const ConsBarChartComponent = ({ data, temoinKey, essentielKey, experienceKey, isTemperature }) => {
  useEffect(() => {
  }, [data]);

  if (!data || data.length === 0) {
      return <p>Pas de données disponibles pour le graphique.</p>;
  }

  const tooltipFormatter = (value, name) => {
    if (name.props.tag === 'Temoin') {
      return [`Témoin : ${value.toLocaleString("fr-FR")} L`, name];
    } else if (name.props.tag === 'Essentiel') {
      return [`Essentiel : ${value.toLocaleString("fr-FR")} L`, name];
    } else if (name.props.tag === 'Experience') {
      return [`Expérience : ${value.toLocaleString("fr-FR")} L`, name];
    } else {
      return [`${value.toLocaleString("fr-FR")} L`, name];
    }
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
            <BarChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {/* Axe X : Mois */}
        <XAxis dataKey="month" />

        {/* Axe Y : Échelle automatique */}
        <YAxis
          ticks={isTemperature ? [0, 15, 30, 45, 60] : [0, 20, 40, 60, 80]}
          tickFormatter={(value) => value.toLocaleString("fr-FR")}
        />

        {/* Infobulle (tooltip) */}
        <Tooltip formatter={tooltipFormatter}/>

        {/* Légende */}
        <Legend />

        {/* Bloc pour "Temoin" */}
        <Bar
          dataKey={temoinKey}
          stackId="a"
          fill="#106198"
          name={<Translation tag="Temoin" />}
          isAnimationActive={false}
          barSize={30}
        />

        {/* Bloc pour "Essentiel" */}
        <Bar
          dataKey={essentielKey}
          stackId="b"
          fill="#365644"
          name={<Translation tag="Essentiel" />}
          isAnimationActive={false}
          barSize={30}
        />

        {/* Bloc pour "Experience" */}
        <Bar
          dataKey={experienceKey}
          stackId="c"
          fill="#90B87B"
          name={<Translation tag="Experience" />}
          isAnimationActive={false}
          barSize={30}

        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ConsBarChartComponent;
