import React, { useEffect } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import Translation from "utils/Translation";

const LineChartComponent_simple = ({ data }) => {
    useEffect(() => {
    }, [data]);

    if (!data || data.length === 0) {
        return <p>Pas de données disponibles pour le graphique.</p>;
    }

    const tooltipFormatter = (value, name) => {
        let unit = "€";

        if(name.props.tag === 'Eau_euro'){
            return [`Eau : ${value.toLocaleString("fr-FR")} ${unit}`, name];
        } else if(name.props.tag === 'Water_euro'){
            return [`Water : ${value.toLocaleString("fr-FR")} ${unit}`, name];
        } else if(name.props.tag === 'Energie_euro'){
            return [`Energie : ${value.toLocaleString("fr-FR")} ${unit}`, name];
        } else if(name.props.tag === 'Energy_euro'){
            return [`Energy : ${value.toLocaleString("fr-FR")} ${unit}`, name];
        }
    }

    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip formatter={tooltipFormatter} />
                <Legend />
                <Bar
                    dataKey="totalEnergySavedInEuros"
                    stackId="a"
                    fill="#346840"
                    name={<Translation tag={"Energie_euro"} />}
                    isAnimationActive={false}
                    barSize={30}
                />
                <Bar
                    dataKey="totalWaterSavedInEuros"
                    stackId="a"
                    fill="#106198"
                    name={<Translation tag={"Eau_euro"} />}
                    isAnimationActive={false}
                    barSize={30}
                />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default LineChartComponent_simple;
